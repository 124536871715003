import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-envio-comandos',
  templateUrl: './envio-comandos.page.html',
  styleUrls: ['./envio-comandos.page.scss'],
})
export class EnvioComandosPage implements OnInit {

  dataComandos:any = [];
  comando:any;
  imei:any = '';
  selec_unidad:any;
  objetos:any = [];
  
  constructor( private _api: ApiService, private route: ActivatedRoute, public alertController:AlertController) { 
    this.route.params.subscribe(params => {
      console.log("params =>",params);
      if (params) {
        this.imei = params.id;
      }

      if( !params.id ){
        this.getObjetos();
      }
    });
  }
  //MANTENER LA LISTA EN BASE DE DATOS EN CASO DE AÑADIR COSAS NUEVAS
  ngOnInit() {
    this.getComandos();
  }

  getObjetos(){
    let user = localStorage.getItem("user");
    this._api.getObjetos({user:user}).subscribe((data:any)=>{
      this.objetos = data.data;
    });
  }

  getComandos(){
    const user = localStorage.getItem("user");
    this._api.getComandos({ user: user }).subscribe((data:any)=>{
      console.log(data);
      if( data.status == 1 ){        
        this.dataComandos = data.data;        
      }
    });
  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertController.create({
      // header: 'Alert',
      subHeader: 'Mensaje',
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async enviar(){

    const alert = await this.alertController.create({
      subHeader: '¿Quieres enviar el comando?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            
          },
        },
        {
          text: 'Si',
          role: 'confirm',
          handler: () => {
            const user = localStorage.getItem("user");

            if( !this.imei ){
              this.imei = this.selec_unidad;
            }

            let dataEnviar = { comando: this.comando, tipo: "gprs",user: user, imei: this.imei };
            console.log(dataEnviar, this.selec_unidad);
            
            this._api.envioDeComando(dataEnviar).subscribe((data:any)=>{ 
              console.log("DATA",data);
              if( data.status == 1 ){
                this.presentAlert(data.mensaje);
                this.getComandos();
              }
            }, (err:any)=>{
              console.log(err);
            });
          },
        },
      ],
    });

    await alert.present();

    
  }

}
